$(function(){

  //===============================================
  /* トップメインスライド */
  //===============================================
  $('#slide').slick({
    autoplay:true,
    dots:true,
    fade:true,
    arrows:false,
    autoplaySpeed: 4000,
  });

  //===============================================
  /* 注目コンテンツ */
  //===============================================
  $('#recommended_slide').slick({
    centerMode: true,
    centerPadding: '20px',
    dots:false,
    arrows: true,
    autoplay: true,
    variableWidth: true,
    speed: 800,
  });


  //===============================================
  /* 広報誌・議会だよりスライド */
  //===============================================
  $('.mag_slide').slick({
    autoplay:true,
    dots:true,
    fade:true,
    arrows:false,
    autoplaySpeed: 4000,
  });

  //===============================================
  /* トップタブコントロール */
  //===============================================
  $('.tab_ctrl li').on('click',function(){
    var tgt = $(this).data('tgt');
    $('.tab_contents').children('section').removeClass('active');
    $('#'+tgt).addClass('active');
    $('.tab_ctrl li').removeClass('active');
    $(this).addClass('active');
  });

});